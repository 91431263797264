/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: User Settings Page for this React Application
*/

import React, { useRef, useState } from 'react';  // import React
import { useNavigate } from "react-router-dom";  // import React-router-dom for multi-page react app
import { useAuth } from '../contexts/AuthenticationContext';  // import Auth from Firebase

/* Import CSS Styles */
import '../App.css'; // import CSS File for the App

/* Import images and other media */
import background from '../images/background.JPG'; // import background image file
import logo from '../images/VineLink_logo.png'; // import logo image file

function PasswordReset() {
    const emailValueRef = useRef()
    const { passwordReset, currentUser } = useAuth() // export password reset method from auth
    const [error, setError] = useState("") // error message state
    const [message, setMessage] = useState("") // message state
    const [loading, setLoading] = useState(false) // form loading state
    const navigate = useNavigate() // useNavigate to navigate back to home after logout

    async function handleResetSubmit(e) {
        e.preventDefault() // prevent form from refreshing

        try {
            setError("") // clear previous error messages
            setLoading(true) // set form to loading state
            await passwordReset(emailValueRef.current.value)
            setMessage("Το e-mail εστάλη επιτυχώς, παρακαλώ ελέγξτε την αλληλογραφία σας")
        } catch (error) {
            setError("ΣΦΑΛΜΑ: Η επαναφορά κωδικού απέτυχε")
            //console.log(error) // print error to console for debugging
        }
        
        setLoading(false) // clear the loading state
    }

    if (currentUser) {
        return navigate("/dashboard") // εναλλακτικό του history.push("link...") για τη νέα έκδοση του React-Router
    }

    return (
        <div className="passwordreset">
            <header className="App-header">
            <div className="container_img" style={{"backgroundImage": "url(" + background + ")"}}>
                    <form className='login_form' style={{"textAlign": "left"}} onSubmit={handleResetSubmit}>
                        <img src={logo} style={
                            {
                                "display": "block",
                                "marginLeft": "auto",
                                "marginRight": "auto",
                                "width": "20%",
                                "height": "20%",
                                "maxWidth": "150px"
                            }
                        } alt="logo"/>
                        <h1 style={{"textAlign": "center"}}>Επαναφορά Κωδικού Πρόσβασης</h1>
                        <p style={{"textAlign": "center"}}>Θα σας αποσταλεί ένα μήνυμα ηλεκτρονικού ταχυδρομείου με περισσότερες οδηγίες</p>
                        <div className="mb-3">
                            <label htmlFor="email_input" className="form-label">Διεύθυνση Email</label>
                            <input type="email" className="form-control" id="email_input" aria-describedby="emailHelp" ref={emailValueRef} placeholder="Εισάγετε τη διεύθυνση e-mail σας" required></input>
                        </div>
                        {
                            message && <div className="alert alert-success" role="alert">{message}</div>
                        }
                        {
                            error && <div className="alert alert-danger" role="alert">{error}</div>
                        }
                        <a style={{"fontSize": "12pt", "display": "inline"}} href="/login">Σύνδεση στην εφαρμογή</a>
                        <button type="submit" className="btn btn-success btn-lg" style={{"float": "right"}} disabled={loading}>Επαναφορά Κωδικού</button>
                        <br></br>
                    </form>
                </div>
            </header>
        </div>
    )
}

export default PasswordReset;
