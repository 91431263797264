/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: Users' Vineyard Dashboard Page for this React Application
*/

import React, { useState, useEffect } from 'react' // import React
import { NavLink } from "react-router-dom"; // import module for multi-page navigation
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'; // import chart.js library
import { Line } from "react-chartjs-2"; // import line chart from Chart.JS Library
import VerificationPrompt from "./VerificationPrompt"; // import verification prompt component
import { useAuth } from '../contexts/AuthenticationContext'; // import Auth from Firebase
import { useFirebaseRealtimeData } from '../contexts/FirebaseContext'; // import Firebase get data API context
import { 
  ref,
  child,
  get
} from "firebase/database"; // import DB functionality from firebase*/
import { rtdb } from "../firebase"; // import realtime database instance from firebase

/* Import CSS Styles */
import '../App.css'; // import CSS File for the App

/* Import images and other media */
//import logo from '../images/VineLink_logo.png'; // import logo image file

function Vineyard() {
  const { currentUser } = useAuth(); // get current user

  const { requestError, requestIsLoaded, dataFetchTime, vineyardData, userPref, reloadUserData } = useFirebaseRealtimeData(); // get the data from Firebase from the relevant context
  
  // States for data display items
  const [totalVineyardNodes, setTotalVineyardNodes] = useState("0"); // number of total nodes on the vineyard
  const [vineyardLocation, setVineyardLocation] = useState(null); // display the location of the vineyard from user preferences
  const [selectedDevice, setSelectedDevice] = useState(null); // variable to store the MAC Address of the selected node device for detailed info view
  const [selectedDeviceLatestData, setSelectedDeviceLatestData] = useState(null); // the latest data of the selected device
  const [timeLabels, setTimeLabels] = useState([]); // the time labels for the charts
  const [tempData, setTempData] = useState([]); // temp data for the chart
  const [humidityData, setHumidityData] = useState([]); // humidity data for the chart
  const [soilData, setSoilData] = useState([]); // soil moisture data for the chart
  const [rainData, setRainData] = useState([]); // rain data for the chart
  const [rainRateData, setRainRateData] = useState([]); // rain rate data for the chart
  const [windData, setWindData] = useState([]); // wind data for the chart
  const [timestampsQueryIsLoaded, setTimestampsQueryIsLoaded] = useState(false); // Firebase Query Is Loaded Flag for Device Timestamps data
  const [yearsList, setYearsList] = useState([]); // the list of the years from the timestamps
  const [selectedYear, setSelectedYear] = useState("Επιλέξτε έτος"); // selected year from the dropdown
  const [timestampQueryData, setTimestampQueryData] = useState(null); // device timestamp query data

  // initialize chart.js
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  /* function to convert epoch time to Greek date and time format */
  function epochDateFormat(epoch) {
    if (epoch === null) {
      return "-";
    }
    return new Intl.DateTimeFormat("el-GR", 
      { weekday:"long", year:"numeric", month:"long", day:"numeric", hour:"2-digit", minute:"2-digit", second:"2-digit" }
    ).format(epoch);
  }

  /* function to handle data reload from database */
  function handleDataReload() {
    //console.log("INFO: handle data reload");
    reloadUserData();
  }

  /* function to handle node device selection */
  function handleSelect(mac_addr) {
    //console.log("INFO: Device selected");
    setSelectedDevice(mac_addr); // set the mac address of the selected node device to state variable
    setSelectedDeviceLatestData(vineyardData[mac_addr]); // set the latest data of the selected node device to state variable
    // GET the data in timeseries format from Firebase for each chart
    if (mac_addr !== null) {
      setTimestampsQueryIsLoaded(false); // update "Is Loaded" Flag
      getTimestampsFromFirebase(mac_addr); // run Firebase Query to get device timestamp data
    }
  }

  /* function to handle year selection from dropdown */
  function handleYearSelect(year) {
    //console.log("INFO: Year filtered");
    setSelectedYear(year); // update selected year
    getChartData(timestampQueryData[year]); // update filtered charts
  }

  /* Function to GET all the Device Timestamps Data with Firebase Query for graph rendering */
  function getTimestampsFromFirebase(mac_addr) {
    if ((currentUser !== undefined) && (currentUser !== null)) {
      const userID = currentUser.uid; // get the user UID
      // run the query with Firebase SDK's get() function
      //console.log("INFO: Running Device timestamps data query");
      const deviceDBRef = ref(rtdb, '/user_data/' + userID + '/Vineyard/Node_MAC_Address/');
      get(child(deviceDBRef, mac_addr)).then((snapshot) => {
        if(snapshot.exists()) {
            //console.log(snapshot.val());
            setTimestampsQueryIsLoaded(true); // update "Is Loaded" Flag
            let received_query_data = snapshot.val();
            setTimestampQueryData(received_query_data); // save received data
            let years_arr = Object.keys(received_query_data); // extract year keys from received data
            setYearsList(years_arr);
            //console.log(years_arr);
            getChartData(received_query_data[years_arr[years_arr.length - 1]]); // get the data for the carts for this device for the latest year by default
            setSelectedYear(years_arr[years_arr.length - 1]); // update selected year
            //console.log("INFO: Device data downloaded successfully");
        }
        else {
          // No data available
          setTimestampsQueryIsLoaded(true); // update "Is Loaded" Flag
        }
      }).catch((error) => {
          console.error(error); // print error to console
      })
    }
  }

  /* function to extract the data from JSON to timeseries for the charts */
  function getChartData(deviceData) {
    // declare arrays to store node's data
    const labels = [];
    const temp = [];
    const humidity = [];
    const soil = [];
    const rain = [];
    const rainRate = [];
    const wind = [];
    
    // filter the timestamps in order to keep only the timestamps with difference of at least 4 hours between them
    // Reference: https://stackoverflow.com/questions/52765086/javascript-filter-based-upon-previous-value
    const timestamp_arr = Object.keys(deviceData);
    const filtered_timestamps = timestamp_arr.filter((object, index) =>
      !index || (object - timestamp_arr[index - 1] > 3*3600)
    );

    for (let i = 0; i < filtered_timestamps.length; i++) {
      var current_temp = deviceData[filtered_timestamps[i]].temp; // store the current temp value
      var current_humidity = deviceData[filtered_timestamps[i]].humidity; // store the current humidity value
      var current_soil = deviceData[filtered_timestamps[i]].soil_moisture; // store the current soil moisture value

      if (current_temp > 0 && current_humidity > 0 && current_soil > 0) { /* select only the data with accepted values (clear the input data) */
        labels.push(deviceData[filtered_timestamps[i]].date_and_time); // append date and time label
        temp.push(deviceData[filtered_timestamps[i]].temp); // append temp value
        humidity.push(deviceData[filtered_timestamps[i]].humidity); // append humidity value
        soil.push(deviceData[filtered_timestamps[i]].soil_moisture); // append soil moisture value
        rain.push(deviceData[filtered_timestamps[i]].meteo_rain); // append rain value
        rainRate.push(deviceData[filtered_timestamps[i]].meteo_rain_rate); // append rain rate value
        wind.push(deviceData[filtered_timestamps[i]].meteo_wind_speed); // append wind value
      }
    }

    setTimeLabels(labels); // store time labels to corresponding state
    setTempData(temp); // store the temp array to corresponding state
    setHumidityData(humidity); // store the humidity array to corresponding state
    setSoilData(soil); // store the soil moisture array to corresponding state
    setRainData(rain); // store the rain array to corresponding state
    setRainRateData(rainRate); // store the rain rate array to corresponding state
    setWindData(wind); // store the wind speed array to corresponding state
  }

  /* function to render a chart */
  function renderChart(chart_title, data_label, data, labels) {
    // chart options variable
    const chart_options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: chart_title,
        },
      }
    };
    // chart data variable
    const chart_data = {
      labels,
      datasets: [
        {
          label: data_label,
          data: data,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        }
      ],
    };

    return <Line options={chart_options} data={chart_data} />;
  }

  /* function to render each device info card */
  function renderNodeCard(key, node) {
    return (
      <div key={key} className="col-sm-6">
        <div className="card">
          <div className="card-body" style={{"textAlign": "left"}}>
            <h3 className="card-title"><div className="alert alert-success" role="alert">ID Κόμβου: #{node.node_id}</div></h3>
            <h5 className="card-text">MAC Διεύθυνση: <span className="badge bg-success">"{node.node_mac}"</span></h5>
            <h5 className="card-text"><i className="bi bi-battery-half" style={{"display": "inline", "margin": "2px"}}></i> Μπαταρία: <span className="badge bg-success">{node.battery_level_percent}%</span></h5>
            <h5 className="card-text"><i className="bi bi-thermometer-half" style={{"display": "inline", "margin": "2px"}}></i> Θερμοκρασία Περιβάλλοντος: <span className="badge bg-primary">{node.temp}<sup>o</sup>C</span></h5>
            <h5 className="card-text"><i className="bi bi-moisture" style={{"display": "inline", "margin": "2px"}}></i> Υγρασία Περιβάλλοντος: <span className="badge bg-primary">{node.humidity}%</span></h5>
            <h5 className="card-text"><i className="bi bi-droplet" style={{"display": "inline", "margin": "2px"}}></i> Υγρασία Εδάφους: <span className="badge bg-primary">{node.soil_moisture}%</span></h5>
            <h5><span className="badge rounded-pill bg-warning">AI</span> Πρόβλεψη Περονόσπορου: <span className="badge bg-warning">Μη διαθέσιμη</span></h5>
            <h5 className="card-text">Λήψη μετρήσεων: <span className="badge bg-info">{node.date_and_time}</span></h5>
            <button className="btn btn-warning btn-lg" style={{"display": "inline-block", "margin": "10px"}} onClick={handleSelect.bind(this, node.node_mac)}>Περισσότερα</button>
          </div>
        </div>
      </div>
    );
  }

  /* function to render the grid with the latest node device info */
  function renderNodesGrid(nodeData) {
    const cells = []; // the list with device info cards
    for (let i = 0; i < totalVineyardNodes; i++) { // append all the device info cards to the list
      cells.push(renderNodeCard(i, nodeData[Object.keys(nodeData)[i]]));
    }

    return (<div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">{cells}</div>);
  }

  /* Update dashboard fields */
  useEffect(() => {
    // use effect to run once, only when data response is ready
    if (vineyardData !== null) {
      setTotalVineyardNodes(Object.keys(vineyardData).length); // update the total vineyard nodes indicator
    }
    if (userPref !== null) {
      setVineyardLocation(userPref.vineyard_location); // update the vineyard location
    }
  }, [vineyardData, userPref])

  return (
    <div className="vineyard">
        <VerificationPrompt></VerificationPrompt> {/* Display warning for unverified users */}
        {/* TO-DO: Replace the code below with the Dashboard page */}
        <header className="App-header">
          <div className="container-fluid">
            <h1>Πίνακας Ελέγχου του Δικτύου στον Αμπελώνα</h1>
            {
              requestError && <div className="alert alert-danger" role="alert">Σφάλμα κατά τη λήψη των δεδομένων: {requestError.message}</div>
            }
            <div style={{"fontSize": "calc(11px + 1vmin)"}}>
              Τελευταία λήψη από τη Βάση Δεδομένων: <span className="badge bg-info">{epochDateFormat(dataFetchTime)}</span>
              <button className="btn btn-warning" style={{"display": "inline-block", "margin": "10px"}} disabled={!requestIsLoaded} onClick={handleDataReload}>
                <i className="bi bi-arrow-counterclockwise" style={{"display": "inline", "margin": "2px"}}></i> Ανανέωση
              </button>
              { /* Add an alert for Loading message while loading data from Firebase */
                !requestIsLoaded && <div className="alert alert-info" role="alert"><h2>Γίνεται φόρτωση...</h2></div>
              }
            </div>
            {
              userPref && userPref.user_preferences_provided === false ?
              <>
                <div className="alert alert-info" role="alert">
                  <h3>Ενημερώστε τις προτιμήσες σας για την ολοκλήρωση της διαδικασίας εγγραφής</h3>
                  <button className="btn btn-success btn-lg">
                    <NavLink className="nav-link" to="/userpreferences">Μετάβαση στις Ρυθμίσεις</NavLink>
                  </button>
                </div>
              </>
              : <></>
            }
            {
              !vineyardData && requestIsLoaded ? <div className="alert alert-warning" role="alert">Δεν βρέθηκαν συσκευές στο δίκυο αισθητήρων του Αμπελώνα</div> : <></>
            }
            {
              vineyardData &&
              <>
                <div className="alert alert-primary" role="alert">
                  <h2>
                    Συνολικός αριθμός συσκευών στο δίκτυο αισθητήρων: <span className="badge bg-success">{totalVineyardNodes}</span>
                  </h2>
                  <h2>
                    <i className="bi bi-pin-map-fill" style={{"display": "inline", "margin": "2px"}}></i> Τοποθεσία Αμπελώνα: <span className="badge bg-primary">{vineyardLocation}</span>
                  </h2>
                </div>
                <div className="container-fluid">
                  <h2>Συγκεντρωτική προβολή των συσκευών:</h2>
                  <div className="container-fluid">
                    {renderNodesGrid(vineyardData)} {/* Render the grid with the latest info from the nodes on the sensor network */}
                  </div>
                </div>
                {
                  selectedDevice !== null ?
                  <div className="container-fluid">
                    <div className="card">
                      <div className="card-header">
                        <h2>Λεπτομερής προβολή της συσκευής με διεύθυνση MAC: <span className="badge bg-success">{selectedDevice}</span></h2>
                      </div>
                      <div className="card-body">
                        <h4>Τελευταία μέτρηση από αυτή τη συσκευή ελήφθη: <span className="badge bg-info">{selectedDeviceLatestData.date_and_time}</span></h4>
                        <h4>Λεπτομέρειες Συσκευής:</h4>
                        <table className="table table-borderless" style={{"tableLayout": "fixed"}}>
                          <tbody>
                            <tr>
                              <td>
                              <h5><i className="bi bi-hash" style={{"display": "inline", "margin": "2px"}}></i> ID Κόμβου στο δίκτυο: <span className="badge bg-success">#{selectedDeviceLatestData.node_id}</span></h5>
                              </td>
                              <td>
                                <h5><i className="bi bi-person-vcard" style={{"display": "inline", "margin": "2px"}}></i> Διεύθυνση MAC Συσκευής: <span className="badge bg-success">{selectedDeviceLatestData.node_mac}</span></h5>
                              </td>
                              <td>
                                <h5><i className="bi bi-battery-half" style={{"display": "inline", "margin": "2px"}}></i> Επίπεδο Μπαταρίας: <span className="badge bg-success">{selectedDeviceLatestData.battery_level_percent}%</span></h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <h4>Μετρήσεις των Αισθητήρων της Συσκευής:</h4>
                        <table className="table table-borderless" style={{"tableLayout": "fixed"}}>
                          <tbody>
                            <tr>
                              <td>
                                <h5><i className="bi bi-thermometer-half" style={{"display": "inline", "margin": "2px"}}></i> Θερμοκρασία Περιβάλλοντος: <span className="badge bg-primary">{selectedDeviceLatestData.temp}<sup>o</sup>C</span></h5>
                              </td>
                              <td>
                                <h5><i className="bi bi-moisture" style={{"display": "inline", "margin": "2px"}}></i> Υγρασία Περιβάλλοντος: <span className="badge bg-primary">{selectedDeviceLatestData.humidity}%</span></h5>
                              </td>
                              <td>
                                <h5><i className="bi bi-droplet" style={{"display": "inline", "margin": "2px"}}></i> Υγρασία Εδάφους: <span className="badge bg-primary">{selectedDeviceLatestData.soil_moisture}%</span></h5>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h5><i className="bi bi-cloud-rain" style={{"display": "inline", "margin": "2px"}}></i> Βροχή: <span className="badge bg-primary">{selectedDeviceLatestData.meteo_rain} mm</span></h5>
                              </td>
                              <td>
                                <h5><i className="bi bi-cloud-rain-heavy" style={{"display": "inline", "margin": "2px"}}></i> Ραγδαιότητα Βροχής: <span className="badge bg-primary">{selectedDeviceLatestData.meteo_rain_rate} mm/h</span></h5>
                              </td>
                              <td>
                                <h5><i className="bi bi-wind" style={{"display": "inline", "margin": "2px"}}></i> Άνεμος: <span className="badge bg-primary">{selectedDeviceLatestData.meteo_wind_speed} km/h</span>, <br></br>Κατεύθυνση ανέμου: <span className="badge bg-primary">{selectedDeviceLatestData.meteo_wind_dir_deg}<sup>o</sup>, {selectedDeviceLatestData.meteo_wind_dir}</span></h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <h4><span className="badge bg-warning">AI</span> Έξυπνη Αξιολόγηση των Δεδομένων από τους Αισθητήρες της Συσκευής:</h4>
                        <table className="table table-borderless" style={{"tableLayout": "fixed"}}>
                          <tbody>
                            <tr>
                              <td>
                                <h5><i className="bi bi-info-circle" style={{"display": "inline", "margin": "2px"}}></i> Πρόβλεψη Εμφάνισης Περονόσπορου<sup><span className="badge rounded-pill bg-warning">AI</span></sup>: <span className="badge bg-warning">Προσεχώς διαθέσιμη λειτουργία...</span></h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <h4>Ιστορικό μετρήσεων των αισθητήρων της συσκευής σε γραφήματα:</h4>
                        {/* Add charts here... */}
                        { /* Add an alert for Loading message while loading data from Firebase */
                          !timestampsQueryIsLoaded && <>
                            <div className="alert alert-info" role="alert"><h2>Γίνεται φόρτωση των γραφημάτων...</h2></div>
                            <button className="btn btn-primary btn-lg" style={{"display": "inline-block", "margin": "10px"}} onClick={handleSelect.bind(this, null)}>Κλείσιμο</button>
                          </>
                        }
                        {
                          timestampsQueryIsLoaded && <>
                            {
                              yearsList && 
                              <div className="container-fluid">
                                <h5>Έτος μετρήσεων: </h5>
                                <div className="dropdown">
                                  <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedYear}
                                  </button>
                                  <div className="dropdown-menu">
                                    {yearsList.map((option, index) => (
                                      <button key={index} className="dropdown-item" onClick={handleYearSelect.bind(this, option)}>{option}</button>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            }
                            {
                              timeLabels.length < 2 ? <div className="alert alert-warning" role="alert">Δεν υπάρχουν προς το παρόν επαρκή δεδομένα για τη σωστή εμφάνιση των παρακάτω γραφημάτων</div> : <></>
                            }
                            {
                              (timeLabels.length > 0 && tempData.length > 0) ? <div className="container_chart" style={{"width": "80%"}}>{renderChart("Θερμοκρασία περιβάλλοντος της συσκευής", "Temperature (Celcius)", tempData, timeLabels)}</div> : <></>
                            }
                            {
                              (timeLabels.length > 0 && humidityData.length > 0) ? <div className="container_chart" style={{"width": "80%"}}>{renderChart("Υγρασία περιβάλλοντος της συσκευής", "Humidity (%)", humidityData, timeLabels)}</div> : <></>
                            }
                            {
                              (timeLabels.length > 0 && soilData.length > 0) ? <div className="container_chart" style={{"width": "80%"}}>{renderChart("Υγρασία Εδάφους της συσκευής", "Soil Moisture (%)", soilData, timeLabels)}</div> : <></>
                            }
                            {
                              (timeLabels.length > 0 && rainData.length > 0) ? <div className="container_chart" style={{"width": "80%"}}>{renderChart("Βροχή στην περιοχή του αμπελώνα", "Rain (mm)", rainData, timeLabels)}</div> : <></>
                            }
                            {
                              (timeLabels.length > 0 && rainRateData.length > 0) ? <div className="container_chart" style={{"width": "80%"}}>{renderChart("Ραγδαιότητα Βροχής στην περιοχή του αμπελώνα", "Rain Rate (mm/h)", rainRateData, timeLabels)}</div> : <></>
                            }
                            {
                              (timeLabels.length > 0 && windData.length > 0) ? <div className="container_chart" style={{"width": "80%"}}>{renderChart("Ταχύτητα Ανέμου στην περιοχή του αμπελώνα", "Wind Speed (km/h)", windData, timeLabels)}</div> : <></>
                            }
                            <button className="btn btn-primary btn-lg" style={{"display": "inline-block", "margin": "10px"}} onClick={handleSelect.bind(this, null)}>Κλείσιμο</button>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                  : <></>
                }
              </>
            }
          </div>
        </header>
    </div>
  )
}

export default Vineyard;
