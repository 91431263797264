/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: Register Page for this React Application
*/

import React/*, { useRef, useState }*/ from 'react';  // import React
import { useNavigate, NavLink } from "react-router-dom";
import { useAuth } from '../contexts/AuthenticationContext'; // import Auth from Firebase

/* Import CSS Styles */
import '../App.css'; // import CSS File for the App

/* Import images and other media */
import background from '../images/background.JPG'; // import background image file
import logo from '../images/VineLink_logo.png'; // import logo image file

function Register() {
    //const emailValueRef = useRef()
    //const userNameValueRef = useRef()
    //const passwdValueRef = useRef()
    //const passwdVfRef = useRef()
    const { currentUser, /*register, editProfile, sendVerification, setInitialUserDetails*/ } = useAuth()
    //const [error, setError] = useState("") // error state
    //const [loading, setLoading] = useState(false) // form loading state
    const navigate = useNavigate() // useNavigate to navigate back to home after logout

    /*async function handleRegisterSubmit(e) {
        e.preventDefault() // prevent form from refreshing

        // password match verification
        if (passwdValueRef.current.value !== passwdVfRef.current.value) {
            return setError("ΣΦΑΛΜΑ: Οι παραπάνω κωδικοί δεν ταιριάζουν")
        }

        try {
            setError("") // clear previous error messages
            setLoading(true) // set form to loading state
            await register(emailValueRef.current.value, passwdValueRef.current.value) // create the new user
            await editProfile(userNameValueRef.current.value) // update new user's display name
            await sendVerification() // send verification email to current user
            await setInitialUserDetails() // set initial user preferences
            navigate("/dashboard") // εναλλακτικό του history.push("link...") για τη νέα έκδοση του React-Router
        } catch (error) {
            setError("ΣΦΑΛΜΑ: Η εγγραφή απέτυχε")
            //console.log(error) // print error to console for debugging
        }

        setLoading(false) // clear the loading state
    }*/

    if (currentUser) {
        return navigate("/dashboard") // εναλλακτικό του history.push("link...") για τη νέα έκδοση του React-Router
    }

    return (
        <div className="register">
            {/* Register Page Content */}
            <header className="App-header">
                <div className="container_img" style={{"backgroundImage": "url(" + background + ")"}}>
                    <form className='login_form' style={{"textAlign": "left"}} onSubmit={null/*handleRegisterSubmit*/}>
                        <img src={logo} style={
                            {
                                "display": "block", 
                                "marginLeft": "auto", 
                                "marginRight": "auto", 
                                "width": "20%",
                                "height": "20%",
                                "maxWidth": "150px"
                            }
                        } alt="logo"/>
                        <h1 style={{"textAlign": "center"}}>Εγγραφείτε στην Εφαρμογή</h1>
                        <div className="alert alert-danger" role="alert">Η εγγραφή νέου χρήστη ΔΕΝ είναι διαθέσιμη προς το παρόν</div>
                        {
                            /*<div className="mb-3">
                                <label htmlFor="email_input" className="form-label">Διεύθυνση Email</label>
                                <input type="email" className="form-control" ref={emailValueRef} placeholder="Εισάγετε ένα έγκυρο e-mail" id="email_input" aria-describedby="emailHelp" required></input>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="username_input" className="form-label">Όνομα Χρήστη</label>
                                <input type="text" className="form-control" id="username_input" ref={userNameValueRef} placeholder="Εισάγετε το όνομα με το οποίο θα εμφανίζεστε στην εφαρμογή" required></input>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password_imput" className="form-label">Κωδικός Πρόσβασης</label>
                                <input type="password" className="form-control" ref={passwdValueRef} placeholder="Δημιουργήστε έναν κωδικό πρόσβασης" id="password_imput" required></input>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password_imput" className="form-label">Επιβεβαίωση Κωδικού Πρόσβασης</label>
                                <input type="password" className="form-control" ref={passwdVfRef} placeholder="Επιβεβαιώστε τον παραπάνω κωδικό πρόσβασης" id="password_confirm" required></input>
                            </div>*/
                        }
                        {
                            /*error && <div className="alert alert-danger" role="alert">{error}</div>*/
                        }
                        {
                            /*<button type="submit" className="btn btn-primary btn-lg" style={{"float": "right"}} disabled={true/*loading*//*}>Εγγραφή</button>
                            <br></br>*/
                        }
                    </form>
                </div>
                <div className="container-fluid">
                    <h2>Εάν έχετε λογαριασμό, Συνδεθείτε</h2>
                    <button className="btn btn-success btn-lg" style={{"margin": "10px"}}>
                        <NavLink className="nav-link" to="/login">Σύνδεση</NavLink>
                    </button>
                </div>
            </header>
        </div>
    );
}

export default Register;
