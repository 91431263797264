/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: User Preferences Page for this React Application
*/

/* TO-DOs:
* [DONE] Add handle form submit function logic
* [DONE] Add fetch data from firebase context to update fields on render
* [DONE] Add update data to firebase from form submit
* [DONE] Add user preferences first provided flag and warning message
* [DONE] Add update preferences prompt to dashboard for new users after registration
*/

import React, { useRef, useState, useEffect } from 'react';  // import React
import VerificationPrompt from "./VerificationPrompt"; // import verification prompt component
import { useAuth } from '../contexts/AuthenticationContext';  // import Auth from Firebase
import { useFirebaseRealtimeData } from '../contexts/FirebaseContext'; // import Firebase get data API context
import { 
  ref,
  set
} from "firebase/database"; // import DB functionality from firebase*/
import { rtdb } from "../firebase"; // import realtime database instance from firebase

/* Import CSS Styles */
import '../App.css'; // import CSS File for the App

/* Import images and other media */
import background from '../images/background.JPG'; // import background image file

function UserPreferences() {
    const vineyardCheckRef = useRef(); // check box UI value reference
    const vineyardLocationRef = useRef(); // vineyard location text box reference
    const wineryCheckRef = useRef(); // check box UI value reference
    const wineryLocationRef = useRef(); // winery location text box reference
    const [error, setError] = useState("") // error state
    const [message, setMessage] = useState("") // message state
    const [loading, setLoading] = useState(false); // form loading state
    /* States for UI fields */
    const [vineyardCheckVal, setVineyardCheckVal] = useState(null);
    const [wineryCheckVal, setWineryCheckVal] = useState(null);
    const [vineyardLocationVal, setVineyardLocationVal] = useState(null);
    const [wineryLocationVal, setWineryLocationVal] = useState(null);

    const { currentUser } = useAuth(); // get current user
  
    /* Firebase Queries */
    const { requestError, userPref, reloadUserData } = useFirebaseRealtimeData(); // get the data from Firebase from the relevant context

    function handleUserPrefUpd(e) {
        e.preventDefault() // prevent form from refreshing
        //console.log("Vineyard check", vineyardCheckRef.current.checked);
        //console.log("Winery check", wineryCheckRef.current.checked);
        //console.log(vineyardLocationRef.current.value, wineryLocationRef.current.value);
        
        // clear error and message
        setError("");
        setMessage("");

        // handle form submit
        try {
            const userId = currentUser.uid; // get user uid
            setLoading(true); // form loading
            set(ref(rtdb, '/user_data/' + userId + '/User_Preferences'), {
                user_preferences_provided: true,
                vineyard_monitoring: vineyardCheckRef.current.checked,
                winery_monitoring: wineryCheckRef.current.checked,
                vineyard_location: vineyardLocationRef.current.value,
                winery_location: wineryLocationRef.current.value
            });
            setMessage("Η ενημέρωση των προτιμήσεων ήταν επιτυχής");
        } catch (error) {
            setError("ΣΦΑΛΜΑ: Δεν ήταν δυνατή η αποθήκευση των προτιμήσεων");
            //console.log(error) // print error to console for debugging
        }
        reloadUserData();
        setLoading(false); // clear form loading
    }

    /* Update dashboard fields */
    useEffect(() => {
        // use effect to run once, only when data response is ready
        if (userPref !== null) {
            setVineyardCheckVal(userPref.vineyard_monitoring);
            setWineryCheckVal(userPref.winery_monitoring);
            setVineyardLocationVal(userPref.vineyard_location);
            setWineryLocationVal(userPref.winery_location);
        }
    }, [userPref])

    return (
        <div className="userpreferences">
            <VerificationPrompt></VerificationPrompt> {/* Display warning for unverified users */}
            {/*
              userPref && userPref.user_preferences_provided === false ?
              <>
                <div className="container-fluid">
                    <div className="alert alert-info" role="alert">
                        <h3>Ενημερώστε τις προτιμήσες σας για την ολοκλήρωση της διαδικασίας εγγραφής</h3>
                    </div>
                </div>
              </>
              : <></>*/
            }
            <header className="App-header">
                <div className="container_img" style={{"backgroundImage": "url(" + background + ")"}}>
                    <form className='login_form' style={{"textAlign": "left"}} onSubmit={handleUserPrefUpd}>
                    <h1 style={{"textAlign": "center"}}>Ενημέρωση Προτιμήσεων Χρήστη</h1>
                    {
                        userPref && userPref.user_preferences_provided === false ?
                            <>
                                <div className="alert alert-info" role="alert">
                                    <h3>Ενημερώστε τις προτιμήσες σας για την ολοκλήρωση της διαδικασίας εγγραφής</h3>
                                </div>
                            </>
                            : <></>
                        }
                        {
                            requestError && <div className="alert alert-danger" role="alert">Σφάλμα κατά τη λήψη των δεδομένων: {requestError.message}</div>
                        }
                        <h2>Περιβάλλον Αμπελώνα</h2>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" defaultChecked={vineyardCheckVal} id="vineyard-check" ref={vineyardCheckRef}></input>
                            <label className="form-check-label" htmlFor="vineyard-check">
                                Παρακολούθηση περιβάλλοντος αμπελώνα
                            </label>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="vineyardLocation_input" className="form-label">Τοποθεσία Αμπελώνα</label>
                            <input type="text" className="form-control" id="vineyardLocation_input" ref={vineyardLocationRef} defaultValue={vineyardLocationVal}></input>
                        </div>
                        <h2>Περιβάλλον Οινοποιείου</h2>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" defaultChecked={wineryCheckVal} id="winery-check" ref={wineryCheckRef}></input>
                            <label className="form-check-label" htmlFor="winery-check">
                                Παρακολούθηση περιβάλλοντος οινοποιείου
                            </label>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="wineryLocation_input" className="form-label">Τοποθεσία Οινοποιείου</label>
                            <input type="text" className="form-control" id="wineryLocation_input" ref={wineryLocationRef} defaultValue={wineryLocationVal}></input>
                        </div>
                        {
                            message && <div className="alert alert-success" role="alert">{message}</div>
                        }
                        {
                            error && <div className="alert alert-danger" role="alert">{error}</div>
                        }
                        <button type="submit" className="btn btn-success btn-lg" style={{"float": "right"}} disabled={loading}>Υποβολή</button>
                        <br></br>
                    </form>
                </div>
            </header>
        </div>
    )
}

export default UserPreferences;
