/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: Footer Component for this React Application
*/

import React from 'react';  // import React
//import { versionInfo } from '../index'; // import version info object from index.js file
import { useAuth } from '../contexts/AuthenticationContext';  // import Auth from Firebase

/* Import CSS Styles */
import '../App.css'; // import CSS File for the App

/* Import images and other media */
import logo from '../images/VineLink_logo.png'; // import logo image file
import personalpic from '../images/Balatsouras.jpg'; // import image file
import companylogo from '../images/logo_BalatsourasC(1).jpg'; // import image file
import reactlogo from '../logo.svg'; // import react logo image file

function Footer() {
    const { currentUser } = useAuth();

    return (
        <div className="footer">
            {/* TO-DO: Add Footer Content Here */}
            <div className="App-footer">
                <div className='container-fluid'>
                    <div className='container text-center'>
                        {/* Add footer as table here... */}
                        {/*<tbody style={
                            {
                                "text-align": "center", 
                                "color": "white"
                            }
                        }>*/}
                        <div className="row">
                            <div className='col-lg-4 col-md-4'>
                                <h4>VineLink Monitoring</h4>
                                <img src={logo} style={
                                    {
                                        "display": "block", 
                                        "marginLeft": "auto", 
                                        "marginRight": "auto", 
                                        "width": "20%",
                                        "maxWidth": "150px"
                                    }
                                } alt="logo"/>
                                <p className="footer_text">Ολοκληρωμένο σύστημα παρακολούθησης αμπελώνα και οινοποιείου σε πραγματικό χρόνο.</p>
                                <p className="footer_text">Η εφαρμογή υλοποιήθηκε στα πλαίσια της Διπλωματικής Εργασίας με θέμα: "Ανάπτυξη ευφυούς δομημένου P2P συστήματος αισθητήρων για υποστήριξη εφαρμογής του διαδικτύου των πραγμάτων (IoT) σε αγροτικό περιβάλλον με τεχνολογίες υπολογιστικού νέφους (Cloud Computing)"</p>
                                <p className="footer_text">Υλοποίηση: Χρήστος-Παναγιώτης Μπαλατσούρας</p>
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <h4>Ανάπτυξη και Σχεδίαση Εφαρμογής</h4>
                                <img src={personalpic} style={
                                    {
                                    "display": "block", 
                                    "marginLeft": "auto", 
                                    "marginRight": "auto", 
                                    "width": "20%",
                                    "maxWidth": "150px"
                                    }
                                } alt="balatsouras"/>
                                <b className="footer_text">Χρήστος-Παναγιώτης Μπαλατσούρας</b>
                                <p className="footer_text">
                                    Υποψήφιος Διδάκτορας του Τμήματος Μηχανικών Η/Υ και Πληροφορικής, Πανεπιστήμιο Πατρών <br></br>
                                    {/*Τηλέφωνο: (+30)6970848795<br></br>
                                    e-mail: <a className='App-link' href='mailto:balatsouras@ceid.upatras.gr'>balatsouras@ceid.upatras.gr</a><br></br>*/}
                                    <a className='App-link' href='https://www.linkedin.com/in/christos-panagiotis-mpalatsouras-66ab07146'>Linkedin</a><br></br>
                                    <a className='App-link' href='https://github.com/takis104'>Github</a><br></br>
                                    <a className='App-link' href='https://scholar.google.com/citations?user=twsAYkIAAAAJ&hl=el'>Christos-Panagiotis Balatsouras @ Google Scholar</a><br></br>
                                </p>
                            </div>
                            <div className='col-lg-4 col-md-4'>
                                <h4>Balatsouras Winery</h4>
                                <p className="footer_text">Αυτή η εφαρμογή υλοποιήθηκε με την υποστήριξη και τη χρηματοδότηση της εταιρείας Οίνοι Μπαλατσούρα ΙΚΕ</p>
                                <p className="footer_text">
                                    <img src={companylogo} style={
                                        {
                                        "display": "block", 
                                        "marginLeft": "auto", 
                                        "marginRight": "auto", 
                                        "width": "20%",
                                        "maxWidth": "150px"
                                        }
                                    } alt="companylogo"/>
                                    Έδρα: Κονιάκος Φωκίδος<br></br>
                                    Ιστότοπος: <a className='App-link' href='https://www.dorianwines.gr'>dorianwines.gr</a><br></br>
                                    Instagram: @dorianwines
                                </p>
                            </div>
                        </div>
                        {/*</tbody>*/}
                    </div>
                    <img src={reactlogo} style={
                        {
                        "display": "block", 
                        "marginLeft": "auto", 
                        "marginRight": "auto", 
                        "width": "10%",
                        "maxWidth": "150px"
                        }
                    } alt="reactlogo"/>
                    <h5 style={{"color": "#61DAFB"}}>Η Σχεδίαση της εφαρμογής έγινε με τη χρήση της τεχνολογίας React</h5>
                    <h6>&copy; Copyright Christos-Panagiotis Balatsouras, {(new Date()).getFullYear()}</h6>
                    {
                        currentUser && <h6><b>Τελευταίο Build: </b>{process.env.REACT_APP_LATEST_BUILD}</h6>
                    }
                </div>
            </div>
        </div>
    );
}

export default Footer;
