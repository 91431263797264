/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: Firebase Authentication Context File
*/

import React, { useContext, useEffect, useState } from "react"; // import react
import { 
    onAuthStateChanged, 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    signOut, 
    sendPasswordResetEmail, 
    updateEmail, 
    updatePassword, 
    updateProfile,
    sendEmailVerification,
} from "firebase/auth";  // import create user function from firebase auth
import { auth } from "../firebase"; // import auth exported variable from firebase.js
import { 
    ref,
    set
} from "firebase/database"; // import DB functionality from firebase
import { rtdb } from "../firebase"; // import realtime database instance from firebase

const AuthenticationContext = React.createContext();  // create the auth context

export function useAuth() {
    return useContext(AuthenticationContext);
}

export function AuthenticationProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(() => {
        const localUser = localStorage.getItem("currentUser"); // get the value stored on local storage
        return localUser ? JSON.parse(localUser) : null; // if a value is set on local storage, retrieve that value to this state. Else set state as null.
    }); // current user state

    /* User Register to firebase function */
    function register(email, password) {
        return createUserWithEmailAndPassword(auth, email, password); // auth.createUserWithEmailAndPassword(email, password)
    }

    /* User Login to firebase function */
    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password); // auth sign in
    }

    /* User Logout */
    function logout() {
        signOut(auth); // user sign out
        setCurrentUser(null); // clear current user
    }

    /* Password Reset */
    function passwordReset(email) {
        return sendPasswordResetEmail(auth, email); // user password reset via email
    }

    /* Update users' email and password */
    function editEmail(newEmail) {
        return updateEmail(auth.currentUser, newEmail); // user email update
    }

    function editPass(newPassword) {
        return updatePassword(auth.currentUser, newPassword); // user password update
    }

    /* Update users' profile */
    function editProfile(newName) {
        return updateProfile(auth.currentUser, {displayName: newName}); // user display name update
    }

    /* Send email verification to the user */
    function sendVerification() {
        return sendEmailVerification(auth.currentUser); // send an email verification link to the current user
    }

    /* initialize user preferences */
    function setInitialUserDetails() {
        if (auth.currentUser) {
            // set initial basic user preferences info
            const userId = auth.currentUser.uid;
            //console.log("set user initial preferences");
            set(ref(rtdb, '/user_data/' + userId + '/User_Preferences'), {
                user_preferences_provided: false,
                vineyard_monitoring: true,
                winery_monitoring: true,
                vineyard_location: "Not provided",
                winery_location: "Not provided"
            });
        }
    }

    useEffect(() => { /* useEffect to run only once */
        /* Sets as current the new user after the login process is completed */
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
        })

        return unsubscribe; // to unsubscribe from the event listener after the registration
    }, []);

    /* Update the local storage on currentUser change */
    useEffect(() => {
      if(currentUser) {
        /* If the current user is not null, update the local storage */
        localStorage.setItem("currentUser", JSON.stringify(currentUser));
      } else {
        /* else, remove the item from the local storage */
        localStorage.removeItem("currentUser");
      }
    }, [currentUser])
    
    /* context values */
    const value = {
        currentUser,
        register,
        login,
        logout,
        passwordReset,
        editEmail,
        editPass,
        editProfile,
        sendVerification,
        setInitialUserDetails
    };

    return (
        <AuthenticationContext.Provider value={value}>
            {children}
        </AuthenticationContext.Provider>
    );
}
