/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: User Main Dashboard Page for this React Application
*/

import React, { useState, useEffect } from 'react' // import React
import VerificationPrompt from "./VerificationPrompt"; // import verification prompt component
import { NavLink } from "react-router-dom"; // import module for multi-page navigation
import { useAuth } from '../contexts/AuthenticationContext'; // import Auth from Firebase
import { useFirebaseRealtimeData } from '../contexts/FirebaseContext'; // import Firebase get data API context

/* Import CSS Styles */
import '../App.css'; // import CSS File for the App

/* Import images and other media */
//import logo from '../images/VineLink_logo.png'; // import logo image file

function Dashboard() {
  const { currentUser } = useAuth(); // get current user
  
  /* Firebase Queries */
  const { requestError, requestIsLoaded, dataFetchTime, vineyardData, wineryData, userPref, reloadUserData } = useFirebaseRealtimeData(); // get the data from Firebase from the relevant context

  // States for data display items
  const [totalVineyardNodes, setTotalVineyardNodes] = useState("0"); // number of total nodes on the vineyard
  const [totalWineryNodes, setTotalWineryNodes] = useState("0"); // number of total nodes on the winery
  const [recentVineyardNode, setRecentVineyardNode] = useState(null); // the most recent sensor data packet from the vineyard
  const [recentWineryNode, setRecentWineryNode] = useState(null); // the most recent sensor data packet from the winery
  const [vineyardLocation, setVineyardLocation] = useState(null) // display the location of the vineyard from user preferences
  const [wineryLocation, setWineryLocation] = useState(null) // display the location of the winery from user preferences

  /* function to convert epoch time to Greek date and time format */
  function epochDateFormat(epoch) {
    if (epoch === null) {
      return "-";
    }
    return new Intl.DateTimeFormat("el-GR", 
      { weekday:"long", year:"numeric", month:"long", day:"numeric", hour:"2-digit", minute:"2-digit", second:"2-digit" }
    ).format(epoch);
  }

  /* function to handle data reload from database */
  function handleDataReload() {
    //console.log("INFO: handle data reload");
    reloadUserData();
  }

  /* Update dashboard fields */
  useEffect(() => {
    // use effect to run once, only when data response is ready
    if (vineyardData !== null) {
      setTotalVineyardNodes(Object.keys(vineyardData).length); // update the total vineyard nodes indicator
      /* Find the node on the Vineyard that sent the most recent Sensor Measurements Packet */
      var mostRecentVineyardNode = vineyardData[Object.keys(vineyardData)[0]]; // set first node as the one that sent the most recent sensor measurements packet
      for (let i = 0; i < Object.keys(vineyardData).length; i++) {
        var currentVineyardNode = vineyardData[Object.keys(vineyardData)[i]]; // set the current node for the for-loop
        if (mostRecentVineyardNode.timestamp < currentVineyardNode.timestamp) { // if the current node has most recent timestamp than the most recent one, set it as most recent node
          mostRecentVineyardNode = currentVineyardNode; // set the new most recent node
        }
      }
      setRecentVineyardNode(mostRecentVineyardNode); // save most recent node to state
    }
    if (wineryData !== null) {
      setTotalWineryNodes(Object.keys(wineryData).length); // update the total winery nodes indicator
      /* Find the node on the Winery that sent the most recent Sensor Measurements Packet */
      var mostRecentWineryNode = wineryData[Object.keys(wineryData)[0]]; // set first node as the one that sent the most recent sensor measurements packet
      for (let i = 0; i < Object.keys(wineryData).length; i++) {
        var currentWineryNode = wineryData[Object.keys(wineryData)[i]]; // set the current node for the for-loop
        //console.log(currentWineryNode);
        if (mostRecentWineryNode.timestamp < currentWineryNode.timestamp) { // if the current node has most recent timestamp than the most recent one, set it as most recent node
          mostRecentWineryNode = currentWineryNode; // set the new most recent node
        }
      }
      setRecentWineryNode(mostRecentWineryNode); // save most recent node to state
    }
    if (userPref !== null) {
      setVineyardLocation(userPref.vineyard_location); // update the vineyard location
      setWineryLocation(userPref.winery_location); // update the winery location
    }
  }, [vineyardData, wineryData, userPref])

  return (
    <div className="dashboard">
        <VerificationPrompt></VerificationPrompt> {/* Display warning for unverified users */}
        {/* TO-DO: Replace the code below with the Dashboard page */}
        <header className="App-header">
          <div className="container-fluid">
            {
              (currentUser && currentUser.metadata ) &&
              <>
                <h1 style={{"textAlign": "left"}}>Καλωσήλθατε @{currentUser.displayName}</h1>
                <div style={{"textAlign": "left", "fontSize": "calc(11px + 1vmin)"}}>Τελευταία σύνδεση: <span className="badge bg-info">{epochDateFormat(currentUser.metadata.lastLoginAt)}</span></div>
              </>
            }
            {
              userPref && userPref.user_preferences_provided === false ?
              <>
                <div className="alert alert-info" role="alert">
                  <h3>Ενημερώστε τις προτιμήσες σας για την ολοκλήρωση της διαδικασίας εγγραφής</h3>
                  <button className="btn btn-success btn-lg">
                    <NavLink className="nav-link" to="/userpreferences">Μετάβαση στις Ρυθμίσεις</NavLink>
                  </button>
                </div>
              </>
              : <></>
            }
          </div>
          <div className='container-fluid'>
            <h1>Όλα τα δίκτυα αισθητήρων με μία ματιά:</h1>
            {
              requestError && <div className="alert alert-danger" role="alert">Σφάλμα κατά τη λήψη των δεδομένων: {requestError.message}</div>
            }
            <div style={{"fontSize": "calc(11px + 1vmin)"}}>
              Τελευταία λήψη από τη Βάση Δεδομένων: <span className="badge bg-info">{epochDateFormat(dataFetchTime)}</span>
              <button className="btn btn-warning" style={{"display": "inline-block", "margin": "10px"}} disabled={!requestIsLoaded} onClick={handleDataReload}>
                <i className="bi bi-arrow-counterclockwise" style={{"display": "inline", "margin": "2px"}}></i> Ανανέωση
              </button>
              { /* Add an alert for Loading message while loading data from Firebase */
                !requestIsLoaded && <div className="alert alert-info" role="alert"><h2>Γίνεται φόρτωση...</h2></div>
              }
            </div>
            {
              userPref && userPref.vineyard_monitoring === true 
              ? <>
                  <div className='container-fluid'>
                    {/* <h2>Δίκτυο Αισθητήρων Αμπελώνα</h2> */}
                    <div className="card">
                      <h2 className="card-header">Δίκτυο Αισθητήρων Αμπελώνα</h2>
                      <div className="card-body">
                        <h4 className="card-title">
                          Συνολικός αριθμός συσκευών στο δίκτυο αισθητήρων: <span className="badge bg-success">{totalVineyardNodes}</span>
                        </h4>
                        <h4 className="card-title">
                          <i className="bi bi-pin-map-fill" style={{"display": "inline", "margin": "2px"}}></i> Τοποθεσία δικτύου αισθητήρων: <span className="badge bg-secondary">{vineyardLocation}</span>
                        </h4>
                        <div className="card-text">
                          {/* Card Content here */}
                          {
                            vineyardData && recentVineyardNode
                            ? <>
                                <h4>Τελευταία μέτρηση από το δίκτυο αισθητήρων ελήφθη: <span className="badge bg-info">{recentVineyardNode.date_and_time}</span></h4>
                                <h4>Λεπτομέρειες Συσκευής:</h4>
                                <table className="table table-borderless" style={{"tableLayout": "fixed"}}>
                                  <tbody>
                                    <tr>
                                      <td>
                                      <h5><i className="bi bi-hash" style={{"display": "inline", "margin": "2px"}}></i> ID Κόμβου στο δίκτυο: <span className="badge bg-success">#{recentVineyardNode.node_id}</span></h5>
                                      </td>
                                      <td>
                                        <h5><i className="bi bi-person-vcard" style={{"display": "inline", "margin": "2px"}}></i> Διεύθυνση MAC Συσκευής: <span className="badge bg-success">{recentVineyardNode.node_mac}</span></h5>
                                      </td>
                                      <td>
                                        <h5><i className="bi bi-battery-half" style={{"display": "inline", "margin": "2px"}}></i> Επίπεδο Μπαταρίας: <span className="badge bg-success">{recentVineyardNode.battery_level_percent}%</span></h5>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <h4>Μετρήσεις των Αισθητήρων της Συσκευής:</h4>
                                <table className="table table-borderless" style={{"tableLayout": "fixed"}}>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <h5><i className="bi bi-thermometer-half" style={{"display": "inline", "margin": "2px"}}></i> Θερμοκρασία Περιβάλλοντος: <span className="badge bg-primary">{recentVineyardNode.temp}<sup>o</sup>C</span></h5>
                                      </td>
                                      <td>
                                        <h5><i className="bi bi-moisture" style={{"display": "inline", "margin": "2px"}}></i> Υγρασία Περιβάλλοντος: <span className="badge bg-primary">{recentVineyardNode.humidity}%</span></h5>
                                      </td>
                                      <td>
                                        <h5><i className="bi bi-droplet" style={{"display": "inline", "margin": "2px"}}></i> Υγρασία Εδάφους: <span className="badge bg-primary">{recentVineyardNode.soil_moisture}%</span></h5>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <h5><i className="bi bi-cloud-rain" style={{"display": "inline", "margin": "2px"}}></i> Βροχή: <span className="badge bg-primary">{recentVineyardNode.meteo_rain} mm</span></h5>
                                      </td>
                                      <td>
                                        <h5><i className="bi bi-cloud-rain-heavy" style={{"display": "inline", "margin": "2px"}}></i> Ραγδαιότητα Βροχής: <span className="badge bg-primary">{recentVineyardNode.meteo_rain_rate} mm/h</span></h5>
                                      </td>
                                      <td>
                                        <h5><i className="bi bi-wind" style={{"display": "inline", "margin": "2px"}}></i> Άνεμος: <span className="badge bg-primary">{recentVineyardNode.meteo_wind_speed} km/h</span>, <br></br>Κατεύθυνση ανέμου: <span className="badge bg-primary">{recentVineyardNode.meteo_wind_dir_deg}<sup>o</sup>, {recentVineyardNode.meteo_wind_dir}</span></h5>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <h4><span className="badge bg-warning">AI</span> Έξυπνη Αξιολόγηση των Δεδομένων από τους Αισθητήρες της Συσκευής:</h4>
                                <table className="table table-borderless" style={{"tableLayout": "fixed"}}>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <h5><i className="bi bi-info-circle" style={{"display": "inline", "margin": "2px"}}></i> Πρόβλεψη Εμφάνισης Περονόσπορου<sup><span className="badge rounded-pill bg-warning">AI</span></sup>: <span className="badge bg-warning">Προσεχώς διαθέσιμη λειτουργία...</span></h5>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            : <>
                                <div className="alert alert-warning" role="alert">Δεν βρέθηκαν συσκευές στο δίκυο αισθητήρων του Αμπελώνα</div>
                              </>
                          }
                        </div>
                        <button className="btn btn-primary btn-lg">
                          <NavLink className="nav-link" to="/vineyard">Περισσότερα</NavLink>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              : <></>
            }
            {
              userPref && userPref.winery_monitoring === true 
              ? <>
                  <div className='container-fluid'>
                    {/* <h2>Δίκτυο Αισθητήρων Οινοποιείου</h2> */}
                    <div className="card">
                      <h2 className="card-header">Δίκτυο Αισθητήρων Οινοποιείου</h2>
                      <div className="card-body">
                        <h4 className="card-title">
                          Συνολικός αριθμός συσκευών στο δίκτυο αισθητήρων: <span className="badge bg-success">{totalWineryNodes}</span>
                        </h4>
                        <h4 className="card-title">
                          <i className="bi bi-pin-map-fill" style={{"display": "inline", "margin": "2px"}}></i> Τοποθεσία δικτύου αισθητήρων: <span className="badge bg-secondary">{wineryLocation}</span>
                        </h4>
                        <div className="card-text">
                          {/* Card Content here */}
                          {
                            wineryData && recentWineryNode
                            ? <>
                                <h4>Τελευταία μέτρηση από το δίκτυο αισθητήρων ελήφθη: <span className="badge bg-info">{recentWineryNode.date_and_time}</span></h4>
                                <h4>Λεπτομέρειες Συσκευής:</h4>
                                <table className="table table-borderless" style={{"tableLayout": "fixed"}}>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <h5><i className="bi bi-person-vcard" style={{"display": "inline", "margin": "2px"}}></i> Διεύθυνση MAC Συσκευής: <span className="badge bg-success">{recentWineryNode.node_mac}</span></h5>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <h4>Μετρήσεις των Αισθητήρων της Συσκευής:</h4>
                                <table className="table table-borderless" style={{"tableLayout": "fixed"}}>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <h5><i className="bi bi-speedometer" style={{"display": "inline", "margin": "2px"}}></i> Θερμοκρασία Δεξαμενής Οίνου: <span className="badge bg-primary">{recentWineryNode.wine_temp}<sup>o</sup>C</span></h5>
                                      </td>
                                      <td>
                                        <h5><i className="bi bi-thermometer-half" style={{"display": "inline", "margin": "2px"}}></i> Θερμοκρασία Οινοποιείου: <span className="badge bg-primary">{recentWineryNode.lab_temp}<sup>o</sup>C</span></h5>
                                      </td>
                                      <td>
                                        <h5><i className="bi bi-moisture" style={{"display": "inline", "margin": "2px"}}></i> Υγρασία Οινοποιείου: <span className="badge bg-primary">{recentWineryNode.lab_humidity}%</span></h5>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            : <>
                                <div className="alert alert-warning" role="alert">Δεν βρέθηκαν συσκευές στο δίκυο αισθητήρων του Οινοποιείου</div>
                              </>
                          }
                        </div>
                        <button className="btn btn-primary btn-lg">
                          <NavLink className="nav-link" to="/winery">Περισσότερα</NavLink>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              : <></>
            }
          </div>
        </header>
    </div>
  );
}

export default Dashboard;
