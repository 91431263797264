/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: Home Page for this React Application
*/

import React from 'react';  // import React
import { NavLink } from "react-router-dom"; // import module for multi-page navigation
import { useAuth } from '../contexts/AuthenticationContext';  // import Auth from Firebase

/* Import CSS Styles */
import '../App.css'; // import CSS File for the App

/* Import images and other media */
import logo from '../images/VineLink_logo.png'; // import logo image file
import background from '../images/background.JPG'; // import background image file

function Home() {
    const { currentUser } = useAuth()

    return (
        <div className="home">
            <header className="App-header">
                <div className="container_img" style={{"backgroundImage": "url(" + background + ")"}}>
                    <img src={logo} className="App-logo" style={
                        {
                          "display": "block", 
                          "marginLeft": "auto", 
                          "marginRight": "auto", 
                          "width": "20%",
                          "height": "20%",
                          "minWidth": "150px"
                        }
                    } alt="logo"/>
                    <h1>Καλωσήλθατε {currentUser && <>@{currentUser.displayName}</>} στο VineLink Monitoring</h1>
                    <p>Ολοκληρωμένο σύστημα παρακολούθησης αμπελώνα και οινοποιείου σε πραγματικό χρόνο με τη χρήση τεχνολογιών του Διαδικτύου των Πραγμάτων (IoT), Υπολογιστικού Νέφους (Cloud Computing) και Μηχανικής Μάθησης (Machine Learning).</p>
                    {/* login, register and about buttons */}
                    {
                        !currentUser &&
                        <>
                            <button className="btn btn-success btn-lg" style={{"margin": "10px"}}>
                                <NavLink className="nav-link" to="/login">Σύνδεση</NavLink>
                            </button>
                            <button className="btn btn-primary btn-lg" style={{"margin": "10px"}}>
                                <NavLink className="nav-link" to="/register">Εγγραφή</NavLink>
                            </button>
                        </>
                    }
                    {
                        currentUser &&
                        <>
                            {/*<a className="btn btn-warning btn-lg" style={{"margin": "10px"}} href="/dashboard" role="button">Πίνακας Ελέγχου</a>*/}
                            <button className="btn btn-warning btn-lg" style={{"margin": "10px"}}>
                                <NavLink className="nav-link" to="/dashboard">Πίνακας Ελέγχου</NavLink>
                            </button>
                        </>
                    }
                    {/*<a className="btn btn-info btn-lg" style={{"margin": "10px"}} href="/about" role="button">Μάθετε περισσότερα</a>*/}
                    <button className="btn btn-info btn-lg" style={{"margin": "10px"}}>
                        <NavLink className="nav-link" to="/about">Μάθετε περισσότερα</NavLink>
                    </button>
                </div>
            </header>
        </div>
    );
}

export default Home;
