/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: Firebase Configuration File
*/

import { initializeApp } from "firebase/app";  // import firebase
import { getAuth } from "firebase/auth"; // import authentication from firebase
import { getDatabase } from "firebase/database"; // import realtime database from firebase

/* Firebase initialization */
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const rtdb = getDatabase(app);
export default app;
