/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: User Settings Page for this React Application
*/

import React, { useRef/*, useState*/ } from 'react';  // import React
import { useAuth } from '../contexts/AuthenticationContext';  // import Auth from Firebase
import VerificationPrompt from "./VerificationPrompt"; // import verification prompt component

/* Import CSS Styles */
import '../App.css'; // import CSS File for the App

/* Import images and other media */
import background from '../images/background.JPG'; // import background image file

function UserSettings() {
    const emailValueRef = useRef()
    const userNameValueRef = useRef()
    const passwdValueRef = useRef()
    const passwdVfRef = useRef()
    const { currentUser/*, editEmail, editPass, editProfile*/ } = useAuth() // export password reset method from auth
    //const [error, setError] = useState("") // error state
    //const [message, setMessage] = useState("") // message state
    //const [loading, setLoading] = useState(false) // form loading state

    /* Function to handle the form submit */
    /*async function handleUpdateSubmit(e) {
        e.preventDefault() // prevent form from refreshing

        // password match verification
        if (passwdValueRef.current.value !== passwdVfRef.current.value) {
            return setError("ΣΦΑΛΜΑ: Οι παραπάνω κωδικοί δεν ταιριάζουν")
        }

        // **** Promises **** //
        // Promise represents completion or failure of an async operation
        // Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise
        const promises = []
        //setLoading(true) // set form to loading state
        setError("") // clear previous error messages

        if (emailValueRef.current.value !== currentUser.email) {
            promises.push(editEmail(emailValueRef.current.value)) // update user's email
        }

        if (userNameValueRef.current.value !== currentUser.displayName) {
            promises.push(editProfile(userNameValueRef.current.value)) // update user's display name
        }

        if (passwdValueRef) {
            promises.push(editPass(passwdValueRef.current.value)) // update user's password if a new password exists in the corresponding form text input
        }

        Promise.all(promises).then(() => {
            setMessage("Η ενημέρωση των στοιχείων ήταν επιτυχής")
        }).catch(() => {
            setError("ΣΦΑΛΜΑ: Η ενημέρωση των στοιχείων απέτυχε")
        }).finally(() => {
            setLoading(false) // clear the loading state either way after all
        })
    }*/

    return (
        <div className="usersettings">
            <header className="App-header">
                <div className="container_img" style={{"backgroundImage": "url(" + background + ")"}}>
                    <form className='login_form' style={{"textAlign": "left"}}> {/*onSubmit={handleUpdateSubmit}>*/}
                        <h1 style={{"textAlign": "center"}}>Ενημέρωση Στοιχείων Χρήστη</h1>
                        {
                            currentUser.emailVerified 
                            ? <div className="alert alert-success" role="alert">Η Διεύθυνση e-mail έχει επιβεβαιωθεί επιτυχώς</div> /* display "verification is successful" message */
                            : <VerificationPrompt></VerificationPrompt> /* if current user is not verified, display warning */ /*<div className="alert alert-warning" role="alert">Η Διεύθυνση e-mail δεν έχει επιβεβαιωθεί. <button className="btn btn-success btn-lg" style={{"margin": "10px"}} onClick={handleVerification} disabled={loading}>Επιβεβαίωση e-mail</button></div>*/
                        }
                        <div className="mb-3">
                            <label htmlFor="email_input" className="form-label">Ενημέρωση Διεύθυνσης Email</label>
                            <input type="email" className="form-control" id="email_input" ref={emailValueRef} aria-describedby="emailHelp" defaultValue={currentUser.email} required disabled={true}></input> {/* Το default value είναι για να συμπληρώνεται το email του τρέχοντα χρήστη από προεπιλογή */}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="username_input" className="form-label">Ενημέρωση Ονόματος Χρήστη</label>
                            <input type="text" className="form-control" id="username_input" ref={userNameValueRef} defaultValue={currentUser.displayName} required disabled={true}></input> {/* Το default value είναι για να συμπληρώνεται το username του τρέχοντα χρήστη από προεπιλογή */}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password_imput" className="form-label">Νέος Κωδικός Πρόσβασης</label>
                            <input type="password" className="form-control" id="password_imput" ref={passwdValueRef} placeholder="Αφήστε το πεδίο κενό για διατήρηση του κωδικού" disabled={true}></input> {/* Το placeholder είναι για το κείμενο που εμφανίζεται εντός του πεδίου κειμένου πρίν από την πληκτρολόγηση */}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password_imput" className="form-label">Επιβεβαίωση Νέου Κωδικού Πρόσβασης</label>
                            <input type="password" className="form-control" id="password_confirm" ref={passwdVfRef} placeholder="Αφήστε το πεδίο κενό για διατήρηση του κωδικού" disabled={true}></input> {/* Το placeholder είναι για το κείμενο που εμφανίζεται εντός του πεδίου κειμένου πρίν από την πληκτρολόγηση */}
                        </div>
                        {
                            /*message && <div className="alert alert-success" role="alert">{message}</div>*/
                        }
                        {
                            /*error && <div className="alert alert-danger" role="alert">{error}</div>*/
                        }
                        <div className="alert alert-danger" role="alert">Η ενημέρωση των στοιχείων του χρήστη είναι προσωρινά μη διαθέσιμη</div>
                        <button type="submit" className="btn btn-success btn-lg" style={{"float": "right"}} disabled={true}>Ενημέρωση Στοιχείων</button>
                        <br></br>
                    </form>
                </div>
            </header>
        </div>
    )
}

export default UserSettings;
