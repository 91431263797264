/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: Navbar file for this React Application
*/

import React from 'react'; // import React
//import { useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom"; // import module for multi-page navigation
import { useAuth } from '../contexts/AuthenticationContext'; // import Auth from Firebase
import { useFirebaseRealtimeData } from '../contexts/FirebaseContext'; // import Firebase get data API context

/* Import images and other media */
import logo from '../images/VineLink_logo.png'; // import logo image file

function Navigationbar() {
    const { currentUser, logout } = useAuth(); // get current user state value
    const { clearUserData } = useFirebaseRealtimeData(); // get the data from Firebase from the relevant context
    const navigate = useNavigate(); // useNavigate to navigate back to home after logout

    /* User Logout function */
    async function handleLogout() {
        try {
            clearUserData();
            await logout();
            navigate("/login"); // εναλλακτικό του history.push("link...") για τη νέα έκδοση του React-Router
        } catch (error) {
            //Η αποσύνδεση απέτυχε
            //console.log(error);
        }
    }

    return (
        <div className="navigationbar">
            <nav className="navbar navbar-expand-lg navbar-dark" style={{"backgroundColor": "#004d18", "marginBottom": "0"}}>
                <div className="container">
                    <NavLink className="navbar-brand" to="/">
                        <img src={logo} width="25" height="25" style={{"margin": "5px"}} className="d-inline-block align-top" alt="" />
                        VineLink Monitoring
                    </NavLink>
                    {/* Responsive navbar button to expand the menu on smaller screens */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            {
                                !currentUser &&
                                <>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/" end> {/* The "end" parameter is useful to show Home link as active only when the Home page is selected*/}
                                            Αρχική Σελίδα
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/about">
                                            Σχετικά με την εφαρμογή
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/login">
                                            Σύνδεση
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/register">
                                            Εγγραφή
                                        </NavLink>
                                    </li>
                                </>
                            }
                            {
                                currentUser &&
                                <>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/dashboard">
                                            Πίνακας Ελέγχου
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/vineyard">
                                            Αμπελώνας
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/winery">
                                            Οινοποιείο
                                        </NavLink>
                                    </li>
                                    <li className="nav-item dropdown"> {/* current user dropdown menu */}
                                        <NavLink className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> {/*TO-DO: Add user name dropdown menu and user settings page for password and username update */}
                                            @{currentUser && currentUser.displayName}
                                        </NavLink>
                                        <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdown" style={{"backgroundColor": "#004d18"}}>
                                            <li className="dropdown-item">
                                                <NavLink className="nav-link" to="/usersettings">
                                                    Ρυθμίσεις Χρήστη
                                                </NavLink>
                                            </li>
                                            <li className="dropdown-item">
                                                <NavLink className="nav-link" to="/userpreferences">
                                                    Προτιμήσεις Χρήστη
                                                </NavLink>
                                            </li>
                                            <li className="dropdown-item">
                                                <NavLink className="nav-link" onClick={handleLogout}>
                                                    Αποσύνδεση
                                                </NavLink>
                                            </li>
                                            <li>
                                                <hr className="dropdown-divider" style={{"borderColor": "white"}} />
                                            </li>
                                            <li className="dropdown-item">
                                                <NavLink className="nav-link" to="/about">
                                                    Σχετικά με το<br></br> VineLink
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                </>
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navigationbar;
