/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: Firebase Realtime Database Read Data Query Context File
 * 
 * Code functionality: Save the received data to a useContext to be accessible by all the other pages
 * 
 * ΓΝΩΣΤΑ ΠΡΟΒΛΗΜΑΤΑ: 
 * [ΕΠΙΛΥΘΗΚΕ] 1. Το context επιστρέφει undefined, ΛΥΣΗ: Προτείνεται η κλήση του context μέσα στο index.js αρχείο
 * [ΕΠΙΛΥΘΗΚΕ] 2. Με την κλήση στο index.js, δεν ενημερώνεται σωστά ο currentUser από το AuthenticationContext με αποτέλεσμα την επιστροφή πολλαπλών σφαλμάτων οταν εμφανίζονται οι αρχικές σελίδες πρίν από το Login αλλά και κατά την επιστροφή των δεδομένων μετά το Login
 * ΛΥΣΗ ΓΙΑ ΤΟ (2): Τροποποίηση του κώδικα ώστε, πρώτα να ενημερώνεται ο currentUser με έγκυρη τιμή και μετά να τρέχουν όλα τα υπόλοιπα με προσθήκη αρκετών if-blocks που ελέγχουν τις συνθήκες υπό τις οποίες ο currentUser είναι έκγυρος και το URL για το HTTP Request είναι έγκυρο, ώστε να γίνει η επιστροφή των δεδομένων του χρήστη.
 * 
 * ΠΡΟΤΕΙΝΟΜΕΝΕΣ ΒΕΛΤΙΩΣΕΙΣ:
 * [ΕΠΙΛΥΘΗΚΕ] 1. Ξεχωριστά HTTP Requests για λήψη μόνο των τελευταίων δεδομένων του αμπελώνα και του οινοποιείου, για μείωση του πλήθους των δεδομένων που λαμβάνονται από το Firebase.
 * Λύση για το (1): Χρήση του Firebase SDK και της συνάρτησης get() για την ξεχωριστή εκτέλεση των queries σχετικά με τη λήψη των πιο πρόσφατων δεδομένων αμπελώνα και οινοποιείου και των προτιμήσεων του χρήστη.
 * [ΕΠΙΛΥΘΗΚΕ] 2. Τα δεδομένα για τα γραφήματα που εμφανίζονται στις συσκευές των dashboards, θα φορτώνουν με ξεχωριστό HTTP Request που θα εκτελείται μόνο αν ο χρήστης επιλέξει την εκάστοτε συσκευή.
 * Λύση για το (2): Βλέπε τον κώδικα στα Vineyard και Winery Dashboards, Χρήση του Firebase SDK και της συνάρτησης get() για τη λήψη των timestamps της συσκευής που επιλέχθηκε με σκοπό τη φόρτωση των γραφημάτων.
*/

import React, { useContext, useEffect, useState } from "react"; // import react
import { useAuth } from './AuthenticationContext';  // import Auth from Firebase
import { 
    ref,
    child,
    get
  } from "firebase/database"; // import DB functionality from firebase*/
import { rtdb } from "../firebase"; // import realtime database instance from firebase

const FirebaseContext = React.createContext() // create the Firebase DB received data context

// function to export the use context for this context
export function useFirebaseRealtimeData() {
    return useContext(FirebaseContext);
}

/* function to assign data to the context and export the context provider */
export function FirebaseProvider({ children }) {
    // Variables regarding User Authentication
    const { currentUser } = useAuth(); // get current user

    // Variables and States for HTTP Request to GET the User's data
    const [requestError, setRequestError] = useState(null); // Firebase HTTP request error variable
    const [requestIsLoaded, setRequestIsLoaded] = useState(false); // Firebase HTTP Request loaded flag
    const [dataFetchTime, setDataFetchTime] = useState(null); // variable to store data fetch timestamp

    // Flags about the Loading status of Firebase Queries with get()
    const [vineyardQueryIsLoaded, setVineyardQueryIsLoaded] = useState(false); // Firebase Query Is Loaded Flag for Vineyard Latest data
    const [wineryQueryIsLoaded, setWineryQueryIsLoaded] = useState(false); // Firebase Query Is Loaded Flag for Winery Latest data
    const [prefQueryIsLoaded, setPrefQueryIsLoaded] = useState(false); // Firebase Query Is Loaded Flag for User Preferences

    // States to store the vineyard and winery data from database
    const [vineyardData, setVineyardData] = useState(null); // state to save retrieved vineyard data
    const [wineryData, setWineryData] = useState(null); // state to save retrieved winery data
    const [userPref, setUserPref] = useState(null); // state to save retrieved user's preferences

    /* -- Firebase Queries Functions -- */
    /* 1. Vineyard Sensor Network Latest Data Query funtion */
    function getVineyardLatestData(userId) {
        // run the query with Firebase SDK's get() function
        //console.log("INFO: Running vineyard latest data query");
        const vineyardDBRef = ref(rtdb, '/user_data/' + userId + '/Vineyard'); // database reference
        get(child(vineyardDBRef, '/Latest_Data')).then((snapshot) => {
            if(snapshot.exists()) {
                //console.log(snapshot.val());
                setVineyardQueryIsLoaded(true); // update "Is Loaded" Flag
                setVineyardData(snapshot.val()); // save query results to corresponding variable
            } else {
                // No data available
                setVineyardQueryIsLoaded(true); // update "Is Loaded" Flag
            }
        }).catch((error) => {
            console.error(error); // print error to console
            setRequestError(requestError+"ERROR in the Query of Vineyard Sensor Network Latest Data\n"); // update error message
        })
    }
    /* 2. Winery Sensor Network Latest Data Query funtion */
    function getWineryLatestData(userId) {
        // run the query with Firebase SDK's get() function
        //console.log("INFO: Running winery latest data query");
        const wineryDBRef = ref(rtdb, '/user_data/' + userId + '/Winery'); // database reference
        get(child(wineryDBRef, "/Latest_Data")).then((snapshot) => {
            if(snapshot.exists()) {
                //console.log(snapshot.val());
                setWineryQueryIsLoaded(true); // update "Is Loaded" Flag
                setWineryData(snapshot.val()); // save query results to corresponding variable
            } else {
                // No data available
                setWineryQueryIsLoaded(true); // update "Is Loaded" Flag
            }
        }).catch((error) => {
            console.error(error); // print error to console
            setRequestError(requestError+"ERROR in the Query of Winery Sensor Network Latest Data\n"); // update error message
        })
    }
    /* 3. User Preferences Query funtion */
    function getUserPreferences(userId) {
        // run the query with Firebase SDK's get() function
        //console.log("INFO: Running user preferences query");
        const userDBRef = ref(rtdb, '/user_data/' + userId); // database reference
        get(child(userDBRef, "/User_Preferences")).then((snapshot) => {
            if(snapshot.exists()) {
                //console.log(snapshot.val());
                setPrefQueryIsLoaded(true); // update "Is Loaded" Flag
                setUserPref(snapshot.val()); // save query results to corresponding variable
            } else {
                // No data available
                setPrefQueryIsLoaded(true); // update "Is Loaded" Flag
            }
        }).catch((error) => {
            console.error(error); // print error to console
            setRequestError(requestError+"ERROR in the Query of User Preferences\n"); // update error message
        })
    }

    /* function to clear user's data on logout */
    function clearUserData() {
        // clear all the user's data
        setRequestError(null);
        setRequestIsLoaded(false);
        setVineyardQueryIsLoaded(false);
        setWineryQueryIsLoaded(false);
        setPrefQueryIsLoaded(false);
        setDataFetchTime(null);
        setVineyardData(null);
        setWineryData(null);
        setUserPref(null);
    }

    /* function to reload user data from firebase */
    function reloadUserData() {
        // clear the existing user data first
        clearUserData();
        // initialize again the firebase reference URL and this context will run again to reload the data
        const userId = currentUser.uid; // get the user UID
        getVineyardLatestData(userId); // run firebase query
        getWineryLatestData(userId); // run firebase query
        getUserPreferences(userId); // run firebase query
    }

    /* Run Firebase Queries once with Use Effect */
    useEffect(() => {
        if ((currentUser !== undefined) && (currentUser !== null)) {
            //console.log("INFO: USER LOGGED IN!!!!");
            const userId = currentUser.uid; // get the user UID
            getVineyardLatestData(userId); // run firebase query
            getWineryLatestData(userId); // run firebase query
            getUserPreferences(userId); // run firebase query
        }
        // next comment to disable warnings
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser])

    /* Use Effect to update the request is Loaded flag after the completion of the firebase queries */
    useEffect(() => {
        if (vineyardQueryIsLoaded === true && wineryQueryIsLoaded === true && prefQueryIsLoaded === true) {
            //console.log("INFO: DATA DOWNLOADED SUCCESSFULLY");
            setRequestIsLoaded(true); // set request is loaded flag to true
            setDataFetchTime((new Date()).getTime()); // store the data fetch time
        }
    }, [vineyardQueryIsLoaded, wineryQueryIsLoaded, prefQueryIsLoaded])
    
    /* Context Data */
    const value = {
        requestError,
        requestIsLoaded,
        dataFetchTime,
        vineyardData,
        wineryData,
        userPref,
        clearUserData,
        reloadUserData
    };

    /* Return the context provider */
    return (
        <FirebaseContext.Provider value={value}>
            {children}
        </FirebaseContext.Provider>
    );
}
