/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: Login Page for this React Application
*/

import React, { useRef, useState } from 'react';  // import React
import { useNavigate, NavLink } from "react-router-dom";  // import React-router-dom for multi-page react app
import { useAuth } from '../contexts/AuthenticationContext';  // import Auth from Firebase

/* Import CSS Styles */
import '../App.css'; // import CSS File for the App

/* Import images and other media */
import background from '../images/background.JPG'; // import background image file
import logo from '../images/VineLink_logo.png'; // import logo image file

function Login() {
    const emailValueRef = useRef()
    const passwdValueRef = useRef()
    const { login, currentUser } = useAuth()
    const [error, setError] = useState("") // error state
    const [loading, setLoading] = useState(false) // form loading state
    const navigate = useNavigate() // useNavigate to navigate back to home after logout

    async function handleLoginSubmit(e) {
        e.preventDefault() // prevent form from refreshing

        try {
            setError("") // clear previous error messages
            setLoading(true) // set form to loading state
            await login(emailValueRef.current.value, passwdValueRef.current.value)
            navigate("/dashboard") // εναλλακτικό του history.push("link...") για τη νέα έκδοση του React-Router
        } catch (error) {
            setError("ΣΦΑΛΜΑ: Η σύνδεση χρήστη απέτυχε")
            //console.log(error) // print error to console for debugging
        }
        
        setLoading(false) // clear the loading state
    }

    if (currentUser) {
        return navigate("/dashboard") // εναλλακτικό του history.push("link...") για τη νέα έκδοση του React-Router
    }

    return (
        <div className="login">
            {/* Login Page Content */}
            <header className="App-header">
                <div className="container_img" style={{"backgroundImage": "url(" + background + ")"}}>
                    <form className='login_form' style={{"textAlign": "left"}} onSubmit={handleLoginSubmit}>
                        <img src={logo} style={
                            {
                                "display": "block",
                                "marginLeft": "auto",
                                "marginRight": "auto",
                                "width": "20%",
                                "height": "20%",
                                "maxWidth": "150px"
                            }
                        } alt="logo"/>
                        <h1 style={{"textAlign": "center"}}>Σύνδεση στην Εφαρμογή</h1>
                        <div className="mb-3">
                            <label htmlFor="email_input" className="form-label">Διεύθυνση Email</label>
                            <input type="email" className="form-control" id="email_input" aria-describedby="emailHelp" ref={emailValueRef} placeholder="Εισάγετε ένα έγκυρο e-mail" required></input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password_imput" className="form-label">Κωδικός Πρόσβασης</label>
                            <input type="password" className="form-control" id="password_imput" ref={passwdValueRef} placeholder="Εισάγετε τον κωδικό πρόσβασης" required></input>
                        </div>
                        {
                            error && <div className="alert alert-danger" role="alert">{error}</div>
                        }
                        <a style={{"fontSize": "12pt", "display": "inline"}} href="/passwordreset">Επαναφορά κωδικού</a>
                        <button type="submit" className="btn btn-success btn-lg" style={{"float": "right"}} disabled={loading}>Σύνδεση</button>
                        <br></br>
                    </form>
                </div>
                <div className="container-fluid">
                    <h2>Δημιουργήστε έναν λογαριασμό χρήστη</h2>
                    <button className="btn btn-primary btn-lg" style={{"margin": "10px"}}>
                        <NavLink className="nav-link" to="/register">Εγγραφή</NavLink>
                    </button>
                </div>
            </header>
        </div>
    );
}

export default Login;
