/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: User Email Verification Prompt Component for this React Application
*/

import React, { useState } from 'react'   // import React
import { useAuth } from '../contexts/AuthenticationContext';  // import Auth from Firebase

/* Import CSS Styles */
import '../App.css'; // import CSS File for the App

function VerificationPrompt() {
    const { currentUser, sendVerification } = useAuth() // export password reset method from auth
    const [verificationError, setVerificationError] = useState("") // error state during email verification
    const [verifyMessage, setVerifyMessage] = useState("") // email verification message state
    const [verificationLoading, setVerificationLoading] = useState(false) // form loading state

    /* Function to handle the email verification */
    async function handleVerification(e) {
        e.preventDefault() // prevent form from refreshing

        try {
            setVerificationError("") // clear previous error messages
            setVerificationLoading(true) // set loading state
            await sendVerification() // send verification email to current user
            setVerifyMessage("Σας έχει αποσταλεί ένας σύνδεσμος επιβεβαίωσης μέσω e-mail")
        } catch (error) {
            setVerificationError("ΣΦΑΛΜΑ: Η επιβεβαίωση μέσω e-mail απέτυχε") // set verification error message
            //console.log(error) // print error to console for debugging
        }

        setVerificationLoading(false) // clear the loading state
    }

    return (
        <div className="verificationprompt">
            {
                !currentUser.emailVerified &&
                <>
                    <div className="container-fluid">
                    {
                        !currentUser.emailVerified &&
                        /* if current user is not verified, display warning */
                        <div className="alert alert-warning" role="alert">
                            <h3>Η Διεύθυνση e-mail δεν έχει επιβεβαιωθεί.</h3>
                            <button className="btn btn-success btn-lg" style={{"margin": "10px"}} onClick={handleVerification} disabled={verificationLoading}>
                                Επιβεβαίωση e-mail
                            </button>
                        </div>
                    }
                    {
                        /* show verification error */
                        verificationError && <div className="alert alert-danger" role="alert">{verificationError}</div>
                    }
                    {
                        /* show verification message */
                        verifyMessage && <div className="alert alert-success" role="alert">{verifyMessage}</div>
                    }
                    </div>
                </>
            }
        </div>
    )
}

export default VerificationPrompt;
