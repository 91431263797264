/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: index.js file for this React Application
*/

// REFERENCE: Handle info for current user: context currentUser from Auth context JS file

import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';  // import Bootstrap CSS
import './index.css';
//import reportWebVitals from './reportWebVitals';
import { 
  Route, 
  BrowserRouter as Router, 
  Routes
} from "react-router-dom"; // import React-router-dom for multi-page react app
import {
  Navigationbar,
  Footer,
  Home,
  About,
  Login,
  Register,
  Dashboard,
  HiddenRoute,
  UserSettings,
  PasswordReset,
  Vineyard,
  Winery,
  UserPreferences
} from "./components";
import { AuthenticationProvider } from './contexts/AuthenticationContext'; // import the authentication context
import { FirebaseProvider } from './contexts/FirebaseContext'; // import Firebase get data API context

/* Application Version information */
/*export const versionInfo = {
  "version": "v1.0 Alpha",
  "build": "12 Ιανουαρίου 2024"
}*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment> {/* Replaced React.StrictMode with React.Fragment */}
    {/*<App />*/}
    <Router>
      <AuthenticationProvider> {/* Auth context provider */}
        <FirebaseProvider>
          <Navigationbar/>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/login" element={<Login />}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/passwordreset" element={<PasswordReset/>}/>
            <Route 
              path='/dashboard' 
              element={
                <HiddenRoute>
                    <Dashboard />
                </HiddenRoute>
              }
            />
            <Route 
              path='/usersettings' 
              element={
                <HiddenRoute>
                  <UserSettings />
                </HiddenRoute>
              }
            />
            <Route 
              path='/userpreferences' 
              element={
                <HiddenRoute>
                  <UserPreferences />
                </HiddenRoute>
              }
            />
            <Route 
              path='/vineyard' 
              element={
                <HiddenRoute>
                  <Vineyard />
                </HiddenRoute>
              }
            />
            <Route 
              path='/winery' 
              element={
                <HiddenRoute>
                  <Winery />
                </HiddenRoute>
              }
            />
            {/* Error 404 page */}
            <Route path='*' 
              element={
                <div className="container-fluid">
                  <div className="alert alert-danger" role="alert">
                    <h1>ΣΦΑΛΜΑ 404: Η σελίδα δεν βρέθηκε.</h1>
                  </div>
                  <a className="btn btn-success btn-lg" style={{"margin": "10px"}} href="/" role="button">Αρχική Σελίδα</a>
                </div>
              }
            />
          </Routes>
          <Footer/>
        </FirebaseProvider>
      </AuthenticationProvider>
    </Router>
  </React.Fragment>
);


/*
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
*/
