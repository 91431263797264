/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: Hidden Route Component Code File
*/

import React from 'react'
import { Navigate } from "react-router-dom"; // import React-router-dom for multi-page react app
import { useAuth } from '../contexts/AuthenticationContext'; // import Auth from Firebase

function HiddenRoute({ children }) {
    const { currentUser } = useAuth() //get the current user from auth context

    return currentUser ? children : <Navigate to="/login"/> // return a component only if currentUser is valid, else redirect to login form page. ALSO: get the user's data from Firebase API context
}

export default HiddenRoute;
